<template>
  <v-row justify="center">
    <v-dialog
      scrollable
      origin="top center"
      v-model="customerDialog"
      :max-width="dialogWidth"
      content-class="customer-dialog"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom"
        >
          <h4 class="custom-header-blue-text font-size-30">
            Select Project or Contract
          </h4>
          <v-spacer></v-spacer>
          <v-btn icon dark color="cyan" v-on:click="$emit('resetAll', true)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-4 px-0 font-size-16">
          <v-container>
            <v-container fluid class="pt-0">
              <h5 class="font-weight-600 pb-4 mx-2 mx-md-4">
                Which project or contract would you like to create this for?
              </h5>
              <table width="100%">
                <tr>
                  <td>
                    <v-text-field
                      ref="search-customer"
                      v-model.trim="customer"
                      :loading="searchLoader"
                      :background-color="
                        !isFocused ? 'grey lighten-3' : undefined
                      "
                      :flat="!isFocused && !isSearching"
                      :placeholder="placeholder"
                      autocomplete="off"
                      class="mx-2 mx-md-4 rounded-lg"
                      dense
                      hide-details
                      solo
                      @blur="isFocused = false"
                      @focus="isFocused = true"
                      @keydown.esc="onEsc"
                      @keydown.enter="onEnter"
                      @keydown.tab="onTab"
                    >
                      <template #prepend-inner>
                        <v-icon
                          :color="!isFocused ? 'grey' : undefined"
                          class="ml-1 mr-2"
                        >
                          mdi-magnify
                        </v-icon>
                      </template>
                    </v-text-field>
                  </td>
                </tr>
              </table>

              <div class="pt-4">
                <!-- <perfect-scrollbar
                  :options="{ suppressScrollX: true }"
                  class="scroll"
                  style="max-height: 60vh; position: relative"
                > -->
                <v-list class="mx-2 mx-md-4" subheader three-line>
                  <v-skeleton-loader
                    v-if="searchLoader"
                    class="custom-skeleton"
                    type="list-item-avatar-two-line"
                  >
                  </v-skeleton-loader>
                  <template v-else>
                    <template v-if="customerList.length > 0">
                      <v-list-item
                        v-on:click="selectCustomer(data)"
                        v-for="(data, index) in customerList"
                        :key="index"
                        link
                      >
                        <template v-if="data.type == 'customer'">
                          <v-list-item-avatar>
                            <v-img
                              :lazy-src="$defaultProfileImage"
                              :src="$assetAPIURL(data.profile_logo)"
                              aspect-ratio="1"
                              class="margin-auto"
                              transition="fade-transition"
                            ></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-chip
                              small
                              color="orange"
                              label
                              class="max-width-85px"
                              outlined
                            >
                              Customer
                            </v-chip>
                            <v-list-item-title
                              class="font-weight-500 font-size-16"
                              >{{ data.display_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              class="font-weight-500 font-size-14"
                            >
                              {{ getDefaultPerson(data.default_person) }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action class="margin-auto">
                            <v-list-item-action-text
                              class="font-weight-500 mb-2 font-size-13"
                              >{{ data.property_count }}
                              Property(s)
                            </v-list-item-action-text>
                            <v-list-item-action-text
                              class="font-weight-500 font-size-13"
                              >{{ data.persons_count }}
                              Contact Person(s)
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </template>
                        <template v-else-if="data.type == 'project'">
                          <v-list-item-avatar size="100">
                            <v-img
                              :lazy-src="$defaultProfileImage"
                              :src="data.profile_logo"
                              aspect-ratio="1"
                              class="margin-auto"
                              transition="fade-transition"
                            ></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-chip
                              small
                              color="orange"
                              label
                              class="max-width-67px"
                              outlined
                            >
                              Project
                            </v-chip>
                            <v-list-item-title
                              class="font-weight-500 font-size-16"
                              >{{ data.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              class="font-weight-500 font-size-14"
                            >
                              <p class="m-0">
                                <b>Project #</b> {{ data.barcode }}
                              </p>
                              <p class="m-0">
                                <b>SOled Job No.</b> {{ data.soled_job_no }}
                              </p>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                        <template v-else-if="data.type == 'contract'">
                          <v-list-item-avatar size="100">
                            <v-img
                              :lazy-src="$defaultProfileImage"
                              :src="data.profile_logo"
                              aspect-ratio="1"
                              class="margin-auto"
                              transition="fade-transition"
                            ></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-chip
                              small
                              color="orange"
                              label
                              class="max-width-80px"
                              outlined
                            >
                              Contract
                            </v-chip>
                            <v-list-item-title
                              class="font-weight-500 font-size-16"
                              >{{ data.subject }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              class="font-weight-500 font-size-14"
                            >
                              <p class="m-0">
                                <b>Contact #</b> {{ data.barcode }}
                              </p>
                              <p class="m-0">
                                <b>SOled Job No.</b> {{ data.soled_job_no }}
                              </p>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </template>
                    <template v-else>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="font-weight-500 font-size-16"
                            >No Project or Contract Found
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </template>
                </v-list>
                <!-- </perfect-scrollbar> -->
              </div>
            </v-container>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>

<script>
import CustomerMixin from "@/core/plugins/customer-mixin";

export default {
  mixins: [CustomerMixin],
  data() {
    return {
      formValid: true,
      formLoading: false,
      isFocused: false,
      searchLoader: true,
      customer: null,
      timeoutLimit: 500,
      timeout: null,
      customerArr: new Object(),
      createCustomerDialog: false,
      customerList: new Array()
    };
  },
  props: {
    customerDialog: {
      type: Boolean,
      required: true,
      default: false
    },
    dialogWidth: {
      type: Number,
      default: 768
    }
  },
  watch: {
    customer(param) {
      if (!param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function() {
          _this.searchCustomer();
        }, _this.timeoutLimit);
      }
    }
  },
  methods: {
    onEsc() {
      this.resetSearch();
    },
    onEnter() {
      if (this.isSearching) {
        this.searchCustomer();
      }
    },
    onTab() {
      if (this.isSearching) {
        this.searchCustomer();
      }
    },
    resetSearch() {
      const _this = this;
      _this.$nextTick(() => {
        _this.customer = null;
        _this.isFocused = false;
      });
    },
    searchCustomer() {
      const _this = this;
      _this.searchLoader = true;
      _this
        .getJobCustomers(_this.customer)
        .then(response => {
          _this.customerList = response;
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.searchLoader = false;
          _this.createCustomerDialog = false;
        });
    },
    selectCustomer(param) {
      if (param.type == "customer") {
        if (
          param.persons_count === 1 &&
          this.lodash.isEmpty(param.single_person) === false
        ) {
          this.$emit("selectCustomerPerson", param.single_person.id);
        }
        if (
          param.property_count === 1 &&
          this.lodash.isEmpty(param.single_property) === false
        ) {
          this.$emit("selectCustomerProperty", param.single_property.id);
        }
        if (
          param.billing_count === 1 &&
          this.lodash.isEmpty(param.single_billing) === false
        ) {
          this.$emit("selectCustomerBilling", param.single_billing.id);
        }
        this.$emit("selectCustomer", param.id);
      } else if (param.type == "project") {
        this.$emit("selectCustomer", param.customer);
        this.$emit("selectProject", param.id);
      } else if (param.type == "contract") {
        this.$emit("selectCustomer", param.customer);
        this.$emit("selectContract", param.id);
      }
    },
    getProfileImage(imagePath) {
      if (!imagePath) {
        return this.$filePath + "storage/uploads/images/no-image.png";
      }
      return this.$filePath + imagePath;
    },
    getDefaultPerson(defaultPerson) {
      if (this.lodash.isEmpty(defaultPerson)) {
        return "";
      }
      return defaultPerson.display_name;
    }
  },
  mounted() {
    this.searchCustomer();
    this.$refs["search-customer"].focus();
  },
  computed: {
    isSearching() {
      return this.customer && this.customer.length > 0;
    },
    placeholder() {
      return this.isFocused
        ? "Start Typing then press enter"
        : "Search Project or Contract...";
    },
    getDefaultImage() {
      return this.$filePath + "storage/uploads/images/no-image.png";
    }
  }
};
</script>
